import React, { Component } from "react";

import ModalView from "../../../../../../../ModalView/ModalView";

import "./SizeGuidance.css";

class SizeGuidance extends Component {
  state = {
    showModal: true,
  };

  setShowModal = () => {
    this.setState({ showModal: false });
    this.props.setSizeGuidanceVisible(false);
  };
  componentDidMount() {
    this.setState({ showModal: this.props.showSizeGuidance });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showSizeGuidance !== this.props.showSizeGuidance) {
      this.setState({ showModal: this.props.showSizeGuidance });
    }
  }

  render() {
    let { productSizeGuideHTML } = this.props;
    let { showModal } = this.state;

    return (
      <>
        <ModalView
          show={showModal}
          onClose={() => {
            this.setShowModal();
          }}
        >
          <div className="size-guidance-container">
            <iframe title="size-guide" srcDoc={productSizeGuideHTML}></iframe>
          </div>
        </ModalView>
      </>
    );
  }
}

export default SizeGuidance;
